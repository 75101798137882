import { TabList } from "@/enums/history-tabs";
import { dateShorter } from "@/utils/date";
import { imapiClient, apiClient } from "@/utils/http";
import { transformMessageList } from "@/utils/message";
import { extractMessage } from "@/utils/message-process/extractMsg";
import { from } from "rxjs";
import { map, pluck, tap } from "rxjs/operators";
import {
  getHistoryFilters,
  getHistoryAiFilter,
  getKuaishouPotentialFilters,
} from "@/utils/historyFilter";
import { uniq } from "lodash";

function getStatusFilter(filters: any) {
  const result: any = {
    reply_status: [],
    type: [],
  };
  ["reply_status_1", "reply_status_2"].forEach((key) => {
    if (filters.includes(key)) {
      result.reply_status.push(key.replace("reply_status_", ""));
    }
  });

  result.type = filters.filter((t: any) => {
    return !t.includes("reply_status_");
  });

  return result;
}

export function getWebHistoryList(data: any) {
  const filters = getHistoryFilters("web");
  if (filters?.length) {
    const type = [];
    if (filters.includes("spoken")) {
      type.push("2");
    }
    if (filters.includes("interaction")) {
      type.push("1");
    }
    if (filters.includes("silent")) {
      type.push("0");
    }
    data.type = type;

    const reply_status = [];
    if (filters.includes("unread_comment")) {
      data.is_offline = 2;
      reply_status.push("1");
    }

    if (filters.includes("read_unreply")) {
      reply_status.push("2");
    }
    data.reply_status = reply_status;
  }

  data.platform = "web";

  const aiFilter = getHistoryAiFilter();
   if (aiFilter !== "" && aiFilter !== '\u0000') data.ai_cs = aiFilter;

  return from(apiClient.post("/worker/visitor/index", data)).pipe(
    pluck("data", "result"),
    tap((res) => {
      res.data = res.data.map((row: any) => {
        row.endTimeText = dateShorter(row.last_event_time);

        let feName = "";
        if (!row.name && row.nickname) {
          feName = row.nickname;
        } else if (row.name && !row.nickname) {
          feName = row.name;
        } else if (row.name === row.nickname) {
          feName = row.name;
        } else {
          feName = `${row.name}(${row.nickname})`;
        }
        row.feName = feName;

        row.location = uniq([row.country, row.province, row.city, row.district])
          .filter((t) => !!t && t !== "中国")
          .join(" ");

        return row;
      });
      res.platform = TabList.web;
      return res;
    })
  );
}

export function getWechatHistoryList(data: any) {
  const filters = getHistoryFilters("wechat");
  if (filters?.length)
    data = {
      ...data,
      ...getStatusFilter(filters),
    };

  const aiFilter = getHistoryAiFilter();
   if (aiFilter !== "" && aiFilter !== '\u0000') data.ai_cs = aiFilter;

  return from(apiClient.post("/worker/visitor-wechat/index", data)).pipe(
    pluck("data", "result"),
    tap((res) => {
      res.data = res.data.map((item: any) => {
        item.lastEventTimeText = dateShorter(item.last_event_time);

        if (item.name && item.name !== item.nickname) {
          item.name = `${item.name}(${item.nickname})`;
        } else {
          item.name = item.nickname;
        }

        if (item.corp?.corp_name) {
          item.app.name += "@" + item.corp.corp_name;
        }

        item.uid = item.uid || item.open_id;

        return item;
      });
      res.platform = TabList.wechat;

      return res;
    })
  );
}

export function getWeiboHistoryList(data: any) {
  const filters = getHistoryFilters("weibo");
  if (filters?.length)
    data = {
      ...data,
      ...getStatusFilter(filters),
    };

  const aiFilter = getHistoryAiFilter();
   if (aiFilter !== "" && aiFilter !== '\u0000') data.ai_cs = aiFilter;

  return from(apiClient.post("/worker/visitor-weibo/index", data)).pipe(
    pluck("data", "result"),
    tap((res) => {
      res.data = res.data.map((item: any) => {
        item.lastEventTimeText = dateShorter(item.last_event_time);

        if (item.name && item.name !== item.nickname) {
          item.name = `${item.name}(${item.nickname})`;
        } else {
          item.name = item.nickname;
        }

        return item;
      });
      res.platform = TabList.weibo;

      return res;
    })
  );
}

export function getDouyinHistoryList(data: any) {
  const filters = getHistoryFilters("douyin");
  if (filters?.length)
    data = {
      ...data,
      ...getStatusFilter(filters),
    };

  const aiFilter = getHistoryAiFilter();
   if (aiFilter !== "" && aiFilter !== '\u0000') data.ai_cs = aiFilter;

  return from(apiClient.post("/worker/visitor-douyin/index", data)).pipe(
    pluck("data", "result"),
    tap((res) => {
      res.data = res.data.map((item: any) => {
        item.lastEventTimeText = dateShorter(item.last_event_time);

        if (item.name && item.name !== item.nickname) {
          item.name = `${item.name}(${item.nickname})`;
        } else {
          item.name = item.nickname;
        }

        return item;
      });
      res.platform = TabList.douyin;

      return res;
    })
  );
}

export function getKuaishouHistoryList(data: any, is_potential = 0) {
  if (is_potential === 0) {
    const filters = getHistoryFilters("kuaishou");
    if (filters?.length)
      data = {
        ...data,
        ...getStatusFilter(filters),
      };

    const aiFilter = getHistoryAiFilter();
     if (aiFilter !== "" && aiFilter !== '\u0000') data.ai_cs = aiFilter;
  } else {
    const potential_types = getKuaishouPotentialFilters();
    if(potential_types?.length === 1) {
      data.potential_type = potential_types.join(",");
    }
  }

  data.is_potential = is_potential;

  return from(apiClient.post("/worker/visitor-kuaishou/index", data)).pipe(
    pluck("data", "result"),
    tap((res) => {
      res.data = res.data.map((item: any) => {
        item.lastEventTimeText = dateShorter(item.last_event_time);

        if (item.name && item.name !== item.nickname) {
          item.name = `${item.name}(${item.nickname})`;
        } else {
          item.name = item.nickname;
        }

        return item;
      });
      res.platform = TabList.kuaishou;

      return res;
    })
  );
}

export function getRedbookHistoryList(data: any) {
  const filters = getHistoryFilters("redbook");
  if (filters?.length)
    data = {
      ...data,
      ...getStatusFilter(filters),
    };

  const aiFilter = getHistoryAiFilter();
   if (aiFilter !== "" && aiFilter !== '\u0000') data.ai_cs = aiFilter;

  return from(apiClient.post("/worker/visitor-redbook/index", data)).pipe(
    pluck("data", "result"),
    tap((res) => {
      res.data = res.data.map((item: any) => {
        item.lastEventTimeText = dateShorter(item.last_event_time);

        if (item.name && item.name !== item.nickname) {
          item.name = `${item.name}(${item.nickname})`;
        } else {
          item.name = item.nickname;
        }

        return item;
      });
      res.platform = TabList.redbook;

      return res;
    })
  );
}


export function getWebHistoryDetail(
  uid: string,
  last_id: string,
  company_id: string,
  direction: string
) {
  const data: any = {
    uid,
    microtime: last_id ? last_id : undefined,
    company_id,
    direction: direction ? direction : "early",
    clear_discard: 1,
  };

  return from(
    apiClient.post("/worker/conversation/visitor-web-history", data)
  ).pipe(
    pluck("data", "result"),
    map((res: any) => {
      if (res.more) {
        res.data.unshift({
          feType: "history",
          microtime: 0,
          body: res.data[0].microtime,
        });
      }

      res.data = extractMessage(
        transformMessageList(res.data, "web", data.uid)
      );

      return res;
    })
  );
}

export function getWechatHistoryDetail(uid: string, last_id: string) {
  const data: any = {
    uid,
    microtime: last_id ? last_id : undefined,
    clear_discard: 1,
  };

  return from(apiClient.post("/worker/conversation/visitor-wechat-history", data)).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.data = res.data.reverse();

      if (res.more) {
        const latestMicrotime = res.data[0].microtime;
        res.data.unshift({
          feType: "history",
          microtime: 0,
          body: latestMicrotime,
        });
      }
      res.data = extractMessage(transformMessageList(res.data, "wechat", uid));

      return res;
    })
  );
}

export function getWeiboHistoryDetail(uid: string, last_id: string) {
  const data: any = {
    uid,
    microtime: last_id ? last_id : undefined,
    clear_discard: 1,
  };

  return from(
    apiClient.post("/worker/conversation/visitor-weibo-history", data)
  ).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.data = res.data.reverse();

      if (res.more) {
        const latestMicrotime = res.data[0].microtime;
        res.data.unshift({
          feType: "history",
          microtime: 0,
          body: latestMicrotime,
        });
      }

      res.data = extractMessage(
        transformMessageList(res.data, "weibo", data.uid)
      );

      return res;
    })
  );
}

export function getKuaishouHistoryDetail(uid: string, last_id: string) {
  const data: any = {
    uid,
    microtime: last_id ? last_id : undefined,
    clear_discard: 1,
  };

  return from(
    apiClient.post("/worker/conversation/visitor-kuaishou-history", data)
  ).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.data = res.data.reverse();

      if (res.more) {
        const latestMicrotime = res.data[0].microtime;
        res.data.unshift({
          feType: "history",
          microtime: 0,
          body: latestMicrotime,
        });
      }

      res.data = extractMessage(
        transformMessageList(res.data, "kuaishou", data.uid)
      );

      return res;
    })
  );
}

export function getRedbookuHistoryDetail(uid: string, last_id: string) {
  const data: any = {
    uid,
    microtime: last_id ? last_id : undefined,
    clear_discard: 1,
  };

  return from(
    apiClient.post("/worker/conversation/visitor-redbook-history", data)
  ).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.data = res.data.reverse();

      if (res.more) {
        const latestMicrotime = res.data[0].microtime;
        res.data.unshift({
          feType: "history",
          microtime: 0,
          body: latestMicrotime,
        });
      }

      res.data = extractMessage(
        transformMessageList(res.data, "redbook", data.uid)
      );

      return res;
    })
  );
}

export function getDouyinHistoryDetail(uid: string, last_id: string) {
  const data: any = {
    uid,
    microtime: last_id ? last_id : undefined,
    clear_discard: 1,
  };

  return from(
    apiClient.post("/worker/conversation/visitor-douyin-history", data)
  ).pipe(
    pluck("data", "result"),
    map((res: any) => {
      res.data = res.data.reverse();

      if (res.more) {
        const latestMicrotime = res.data[0].microtime;
        res.data.unshift({
          feType: "history",
          microtime: 0,
          body: latestMicrotime,
        });
      }

      res.data = extractMessage(
        transformMessageList(res.data, "douyin", data.uid)
      );

      return res;
    })
  );
}
